import { Footer } from '../Footer';
import { Header } from '../Header';

export function Consulting() {
  return (
    <>
      <Header />

      <div className="team__cards">
        <h1>Asesoramiento de Neurodivergencias</h1>
        <div className="team__cards--text">
          <section className="consultingction">
            <article className="consultingction__title">
              <a
                className="emailLink"
                href="https://forms.gle/XczPAoxwFhU7NZBU9"
                title="Rellena el formulario"
              >
                Rellena el formulario y empieza tu proceso
              </a>{' '}
            </article>

            <section className="explanation">
              <br />
              <p className="displaynone">
                Durante nuestras sesiones te acompañaré a conocer tu identidad
                detrás del masking, a respetar tus necesidades sensoriales y a
                practicar la compasión contigo misma/o/e. Te ayudaré a
                identificar tus fortalezas para sacarles el mayor partido y tus
                desafíos para introducir herramientas y adaptaciones.
              </p>
              <br />

              <h2>Atiendo online y te puedo ayudar a:</h2>
              <br />
              <ul className="aboutmelist__list">
                <p className="aboutmelist__list--title">
                  Personas Neurodivergentes o que sospechen serlo:
                </p>
                <li className="aboutmelist__list--item">
                  Detección de Neurodivergencias
                </li>
                <li className="aboutmelist__list--item">
                  Identificar, comprender y regular tus características
                  neurodivergentes (autismo, (t)dah, altas capacidades)
                </li>
                <li className="aboutmelist__list--item">
                  Preveción y gestión de Crisis (Meltdown y Shutdown)
                </li>
                <li className="aboutmelist__list--item">
                  Identificación de retos y fortalezas
                </li>
                <li className="aboutmelist__list--item">
                  Dificultades sensoriales y adaptaciones
                </li>
                <li className="aboutmelist__list--item">
                  Dejar de hacer masking y conocerte detrás de él
                </li>
                <li className="aboutmelist__list--item">
                  Preparación del diagnóstico formal por lo público o lo privado{' '}
                </li>
                <li className="aboutmelist__list--item">
                  Informe para diagnóstico formal
                </li>
                <li className="aboutmelist__list--item">
                  Preparación de la solicitud de la Discapacidad
                </li>
                <li className="aboutmelist__list--item">
                  Asesoramiento laboral/asesoría para el emprendimiento
                </li>
                <li className="aboutmelist__list--item">Sexualidad</li>
                <li className="aboutmelist__list--item">Gestión económica</li>
                <li className="aboutmelist__list--item">Alimentación</li>
                <li className="aboutmelist__list--item">Menstruación</li>
                <li className="aboutmelist__list--item">
                  Preparación de retos (viajes, exámenes, entrevistas...)
                </li>
                <li className="aboutmelist__list--item">
                  Embarazo y puerperio (maternidad/paternidad/aternidad)
                </li>
                <li className="aboutmelist__list--item">
                  Descubrir posibles coocurrencias
                </li>
              </ul>
            </section>

            <section className="explanation">
              <br />
              <p>
                Durante nuestras sesiones te acompañaré a conocer tu identidad
                detrás del masking, a respetar tus necesidades sensoriales y a
                practicar la compasión contigo misma/o/e. Te ayudaré a
                identificar tus fortalezas para sacarles el mayor partido y tus
                desafíos para introducir herramientas y adaptaciones.
              </p>
              <br />
              <p className="italic underline">¿Cuánto dura el proceso?</p>
              <br />
              <p>
                <span className="bold">Viaje hacia la calidad de vida</span>{' '}
                (abordaje exhaustivo y profundo): Realizaremos un proceso de
                Detección de Neurodivergencias exhaustivo y profundo que te
                ayudará a entenderte, cuidarte y conseguir calidad de vida.
              </p>
              <p>
                Hablaremos de tus sospechas, abordaremos tus antecedentes
                personales y familiares y los relacionaremos con tus vivencias y
                neurodivergencias. Detectaremos tus fortalezas para
                aprovecharlas y tus desafíos para proponer adaptaciones,
                hablaremos de tus necesidades sensoriales y herramientas para
                gestionarlas. Haremos plan de prevención y gestión de crisis,
                hablaremos de tus intereses especiales y cómo aprovecharlos para
                encontrarte mejor y regularte... Detectaremos co-ocurrencias
                (trauma, toc, ansiedad social, alexitimia…).
              </p>
              <br />
              <p>
                Realizaremos listas de rasgos y test de cribados juntas/es/os,
                te explicaré las preguntas y te pondré ejemplos para que haya
                menor probabilidad de falso negativo.
              </p>
              <br />
              <p>
                Te acompañaré durante tus sucesos vitales desde la compasión
                ayudándote a deconstruir la exigencia y el capacitismo
                interiorizado, brindándote herramientas de gestión adaptadas a
                tus neurodivergencias.
              </p>
              <br />
              <p>
                Te brindaré información sobre las vías para conseguir el
                Diagnóstico Formal para que puedas elegir la opción que más se
                adapte a ti y prepararemos juntas/os/es el proceso. Además, te
                explicaré el proceso de solicitud de Discapacidad y cuáles
                podrían ser los retos.{' '}
              </p>
              <br />
              <p>
                La duración del proceso depende de tus necesidades, prioridades
                y objetivos.
              </p>
              <br />
              <p>
                <span className="bold">
                  Esquema de mis neurodivergencias (abordaje básico):
                </span>{' '}
                Proceso cerrado de detección destinado a personas con habilidad
                en la introspección, fluidez verbal y habilidad en realizar
                tareas entre citas. Suele durar 5-10 citas. Hablaremos de tus
                sospechas, antecedentes personales y familiares, fortalezas y
                desafíos, necesidades sensoriales, crisis, intereses...
                Realizarás test de cribado, tareas de auto observación...
                Posteriormente dedicaremos una cita a devolución y tips de
                regulación.{' '}
              </p>
              <br />
              <p>
                Después puedes pedir el abordaje profundo, o alguno de los otros
                servicios arriba mencionados sin problema.
              </p>

              <br />
              <p className="italic underline">¿Cómo funciona?</p>
              <br />
              <p>
                El primer paso es rellenar este{' '}
                <a
                  className="contactlink writeme__link"
                  href="https://forms.gle/XczPAoxwFhU7NZBU9"
                  title="Rellena el formulario"
                  target="_blank"
                  rel="noreferrer"
                >
                  formulario.
                </a>
              </p>
              <br />
              <p className="italic underline">¿Cuáles son los precios?</p>
              <br />
              <ul className="aboutmelist__list">
                <li className="aboutmelist__list--item">
                  Una cita por <span className="green">80€ (66,11+IVA)</span>.
                </li>
                <li className="aboutmelist__list--item">
                  Pack de <span className="green">2 citas </span> por{' '}
                  <span className="green">160€ (132,23+IVA).</span>
                </li>
                <li className="aboutmelist__list--item">
                  Pack de <span className="green">4 citas </span> por{' '}
                  <span className="green"> 310€ (256,2+IVA)</span>, ahorras 10€.
                </li>
              </ul>
              <br />
              <p>
                <span className="bold">
                  Métodos de pago: BIZUM y Transferencia.
                </span>
              </p>
              <br />
              <br />
              <p className="italic underline">¿Cómo solemos trabajar?</p>
              <br />
              <p>
                Las citas duran aproximadamente 60 minutos y se realizan
                mediante google Meet. Cuando reciba el pago de la cita, te
                enviaré el enlace de acceso. Además la cita se añadirá
                automáticamente a tu Calendario de Google y recibirás
                recordatorios.
              </p>
              <br />
              <p>
                La frecuencia recomendada para vernos es una vez a la semana ,
                sobre todo al principio, ya que durante esas citas sentaremos
                las bases del proceso y de nuestra relación. Posteriormente y en
                función de tus intereses las citas pueden espaciarse .
              </p>
              <br />
              <p>
                Antes de nuestra primera cita, te enviaré un formulario que
                leeré antes de conocernos.
              </p>
              <br />
              <p className="italic underline">¿Cuál es mi filosofía?</p>
              <br />
              <p>
                Es un proceso horizontal en el que mediante listas de rasgos,
                test y conversaciones detectamos e identificamos las
                Neurodivergencias presentes.
              </p>
              <br />
              <p>
                Tengo un enfoque centrado en el cuerpo en el que detectamos los
                marcadores somáticos, es decir, las señales que envía tu cuerpo.
                Aprenderemos a identificarlas, interpretarlas y gestionarlas.
              </p>
              <br />
              <p>
                Realizo un abordaje sensorial, identificaremos tus necesidades
                sensoriales y procuraremos incrementar los estímulos reguladores
                y limitar o intrudir adaptaciones ante los dereguladores.
              </p>
              <br />
              <p>
                Aprenderemos qué emociones hay, cómo se experimentan y para qué
                sirven. Para conseguir una relación contingente con tus
                emociones.
              </p>
              <br />
              <p>
                Crearemos un vínculo saludable basado en el cuidado mutuo que
                será la base del proceso y referente para nuevos vínculos
                cuidadosos.
              </p>
              <br />
              <p className="italic underline">¿En qué horario trabajo?</p>
              <br />
              <p>
                Trabajo de Lunes a Viernes de 8:00 de la mañana a 2:00 de la
                tarde.
              </p>
              <br />
            </section>
            <article className="consultingction__title">
              <a
                className="emailLink"
                href="https://forms.gle/XczPAoxwFhU7NZBU9"
                title="Rellena el formulario"
              >
                Rellena el formulario y empieza tu proceso
              </a>{' '}
            </article>
          </section>
        </div>
      </div>

      <Footer />
    </>
  );
}
